/**
 Button with attached string of visible attachments
 */
import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {Button} from "react-bootstrap";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {areSetsEqual, fileKey, isImage, xhrUpload} from "../../lib/utils";

const FileAttachments = ({
                           uploadURL,
                           className,
                           value = [],
                           disabled = false,
                           onChange = (files) => {},
                           onFileUploadProgress = (file, progress) => {},
                           onFileUploadComplete = (file, response) => {},
                           onFileUploadError = (file, error) => {},
                           limit = null,
                           documentLimit = null,
                           documentSizeLimit = null,
                           imageLimit = null,
                           imageSizeLimit = null,
                           accept = null,
                           readOnly = false,
                           authData = {},
                         }) => {

  const acceptableTypes = new Set(accept || [])
  const fileInputRef = useRef();
  // Map of fileKey => uploadState
  const [selectedFiles, setSelectedFiles] = useState({});

  useEffect(() => {
    const local = new Set(Object.values(selectedFiles).map(s => fileKey(s.file)));
    const requested = new Set(value.map(f => fileKey(f)));
    if (!areSetsEqual(local, requested)) {
      setSelectedFiles(value.reduce((result, file) => {
        result[fileKey(file)] = {file, progress: 0, active: !readOnly && !disabled};
        if (!readOnly && !disabled) {
          uploadFile(file, authData).then(r => {});
        }
        return result;
      }, {}));
    }
    else {
      //console.log("Value unchanged", value, local, requested);
    }
  }, [value]);

  const sanitizedName = (s) => {
    // According to limitations specified in https://docs.aws.amazon.com/bedrock/latest/APIReference/API_runtime_DocumentBlock.html
    return s.replace(/[^-()[\]\s\w]/, " ").replace(/\s{2,}/, " ").substring(0, 200);
  };

  const uploadFile = async (file, authData) => {
    //console.log(`uploadFile: ${file.name}`);
    return xhrUpload(uploadURL, file, handleProgress, authData)
      .then(state => {
        onFileUploadComplete(file, state.response);
      })
      .catch(state => {
        //console.log("uploadFile.catch", state);
        if (state.status === 401 && authData) {
          // Refresh credentials and retry
          //console.log("Refresh creds")
          return authData.refresh()
            .then(newAuthData => uploadFile(file, newAuthData))
            .catch(error => {
              state.error = error;
              handleError(state);
            })
        }
        else {
          handleError(state);
        }
      });
  };

  useEffect(() => {
    onChange(Object.values(selectedFiles).reduce((result, updateState) => {
      result.push(updateState.file);
      return result;
    }, []));
  }, [selectedFiles]);

  const handleProgress = uploadState => {
    setSelectedFiles(files => {
      const updated = {...files};
      updated[fileKey(uploadState.file)] = uploadState;
      return updated;
    });
    onFileUploadProgress(uploadState.file, uploadState.progress);
  };

  const handleError = uploadState => {
    setSelectedFiles(files => {
      const updated = {...files};
      updated[fileKey(uploadState.file)] = uploadState;
      return updated;
    })
    onFileUploadError(uploadState.file, uploadState.error, uploadState.response);
  };

  const handleAttach = (e) => {
    // NOTE: input returns a FileList, not an array
    const inputFiles = [...fileInputRef.current?.files || []];
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    //console.log(`Attach ${inputFiles.length} files`)
    const countImages = files => files.reduce((count, f) => isImage(f) ? count + 1 : count, 0);
    const warnings = new Set();
    //console.log("setSelectedFiles", inputFiles);
    setSelectedFiles(files => {
      //console.log("current files", files);
      const updated = {...files};
      inputFiles.forEach(file => {
        const extantKeys = new Set(Object.values(updated).map(s => fileKey(s.file)));
        const key = fileKey(file);
        const fileType = file.type;
        if (extantKeys.has(key)) {
          //console.log("Already have", file);
        }
        else {
          const nImages = countImages(Object.values(updated).map(s => s.file));
          //console.log(`image count: ${nImages}`);
          if (limit && extantKeys.size >= limit) {
            warnings.add(`You may attach no more than ${limit} files`);
          }
          if (isImage(file)) {
            if (imageLimit && nImages >= imageLimit) {
              warnings.add(`You may attach no more than ${imageLimit} images`);
              return;
            }
            if (imageSizeLimit && file.size > imageSizeLimit) {
              warnings.add(`Image file size may not exceed ${Math.floor(imageSizeLimit / (1024 * 1024))}Mb`)
              return;
            }
          }
          else {
            if (documentLimit && extantKeys.size - nImages >= documentLimit) {
              warnings.add(`You may attach no more than ${documentLimit} non-image documents`);
              return;
            }
            if (documentSizeLimit && file.size > documentSizeLimit) {
              warnings.add(`Document file size may not exceed ${Math.floor(documentSizeLimit / (1024 * 1024))}Mb`)
              return;
            }
          }
          if (accept && acceptableTypes.has(fileType)) {
            warnings.add(`Plexy may not be able to process the document '${file.name}' (type ${fileType})`);
          }
          updated[key] = {file, progress: 0, active: true};
          uploadFile(file, authData).then(r => {});
        }
      });
      return updated;
    });
    Array.from(warnings).forEach(el => {
      toast.warn(el);
    });
  };

  const handleUnattach = file => {
    const key = fileKey(file);
    setSelectedFiles(files => {
      const updated = {...files};
      updated[key]?.xhr?.abort();
      delete updated[key];
      return updated;
    });
  }

  const inputProps = accept ? {accept} : {};
  //console.log("Current value", value, selectedFiles);
  return (
    <div className={`file-uploader${className && ' ' + className || ''}`} >
      {readOnly
       ? (<Button className={"glyphicon glyphicon-paperclip attach-button read-only"}></Button>)
       : (<Button
          className={"glyphicon glyphicon-paperclip attach-button"}
          onClick={() => fileInputRef.current?.click()}
          title={"Attach files"}
          disabled={disabled || (limit && Object.keys(selectedFiles).length >= limit)}
        >
          <input
            ref={fileInputRef}
            onChange={handleAttach}
            type="file"
            multiple={true}
            hidden
            {...inputProps}
          />
        </Button>)}
      {Object.entries(selectedFiles).map(([key, uploadState]) => {
        //console.log(`Render`, {...uploadState});
        const {file = null} = uploadState;
        const errorClass = uploadState?.status === 401
                           ? ' warning-message'
                           : uploadState?.error
                             ? ' error-message' : '';
        return file && (
          <span key={key}
                className={`attached-file${errorClass}${uploadState?.active ? ' disabled': ''}`}
                title={uploadState?.error ? "Attachment failed, remove and retry" : "This file has been attached"}
          >
            {!readOnly && !disabled && (
              <span
                key={"remove"}
                className={`glyphicon glyphicon-remove filter-box-token-delete delete-button`}
                title={"Remove this file from the upload list"}
                onClick={() => handleUnattach(file)}
              />)}
            {file.name}
            {uploadState?.active && (
              <div className={"circular-progress"}>
                <CircularProgressbar value={uploadState?.progress || 0} key={"progress"} strokeWidth={50} styles={buildStyles({strokeLinecap: "butt"})}/>
              </div>
            )}
          </span>
        );
      })}
    </div>
  )
};

export default FileAttachments;
