import React, {useState} from 'react'
import Form from "react-bootstrap/Form";

const Checkbox = ({
    disabled = false,
    style = {},
    className = "",
    title = "",
    label = "",
    onChange = (value) => {},
    children = [],
    // either "checkbox" or "radio"
    checkType = "checkbox",
    // defaultValue or value, but not both
    isSelected = null,
    defaultIsSelected = null,
    value = null,
    defaultValue = null,
    checked = null,
    defaultChecked = null,
}) => {
    const v = isSelected || value || checked;
    const dv = defaultIsSelected || defaultValue || defaultChecked;
    const valueProp = v != null ? {checked: v} : {defaultChecked: dv};
    const handleChange = (e, type) => {
        e.stopPropagation();
        e.preventDefault();
        //console.debug(`checkbox ${type}`);
        onChange(!(v || dv));
    };
    return (
      <span className={`ctm__checkbox${checkType === "radio" ? ' radio' : ''}${className ? " " + className : ""}${disabled ? " disabled" : ""}`} style={style}>
        <label
          title={title}
          className={`ctm__checkbox--label`}
          onClick={(e) => !disabled && handleChange(e, "label-click")}
        >
          <input
            title={title}
            type={checkType}
            className={`ctm__checkbox--input`}
            key={label || Math.random()}
            disabled={disabled}
            onClick={e => handleChange(e, "input-click")}
            onChange={e => handleChange(e, "change")}
            {...valueProp}
          />
          <span title={title} onClick={(e) => !disabled && handleChange(e, "ctm-click")} className={`ctm__checkbox--span`} />
          <span title={title} >{label}</span>
          {children}
        </label>
      </span>
    );
};

export const XCheckbox = (props) => {
    return (<Form.Check type={"checkbox"} {...props} />);
};

export const XRadioButton = (props) => {
    return (<Form.Check type={"radio"} {...props} />);
};

export const RadioButton = (props) => (<Checkbox checkType={"radio"} {...props} />);

export const RadioButtonGroup = ({
                                     className = null,
                                     legendClassName = null,
                                     legend = "Options",
                                     disabled = false,
                                     children = []}) => {
    return (
      <fieldset className={`radio-group${className ? ' ' + className : ''}${disabled ? " disabled" : ""}`} >
          <legend className={`${legendClassName ? legendClassName : ''}`}>{legend}</legend>
          <div>{children}</div>
      </fieldset>
    );
};

export default Checkbox;
