import React from "react";
import logo from "../img/official_logo.png";
import { Link } from "react-router-dom";
import SearchBox from "./SearchBox";
import FiltersBox from "./FiltersBox";
import TabBar from "./TabBar";
import {Navbar, Image, Nav} from "react-bootstrap";
import SettingsMenu from "./menus/SettingsMenu";
import UserMenu from "./menus/UserMenu";
import InfoMenu from "./menus/InfoMenu";
import ExportMenu from "./menus/ExportMenu";
import {withPropsChecker} from "../lib/utils";

const NavBar = props => {
  const {searchSessionStore: {clear: clearSearch}} = props;
  return (
    <Navbar collapseOnSelect className={props.className}>
      <div className="navbar-controls container-fluid">
        <Navbar.Brand>
          <Link tabIndex={0} to="/" onClick={() => clearSearch()}>
            <Image src={logo} className="navbar-logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle data-bs-target="navbar-menus"/>
        <Navbar.Collapse>
          <Nav className="navbar-searchbox navbar-form me-auto" tabIndex={1}>
            <SearchBox />
          </Nav>
          <Nav id="navbar-menus" className="navbar-menus ms-auto">
            <Link className="navbar-logo-mini" to="/" onClick={() => clearSearch()}>
              <Image src={logo} className="navbar-logo-mini" />
            </Link>
            <div className="client-logo" />
            <ExportMenu />
            <UserMenu />
            <SettingsMenu />
            <InfoMenu />
          </Nav>
        </Navbar.Collapse>
      </div>
      <Nav className="navbar-footer" >
        <FiltersBox />
        <TabBar {...props} />
      </Nav>
    </Navbar>
  );
};

export default NavBar;
