import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router";
import logo from "../img/official_logo.png";
import {inject, observer} from "mobx-react";
import {setPageTitle} from "../lib/utils";

const MaintenancePage = (props) => {
  const {
    appStatusStore: {serverOffline = false},
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!serverOffline) {
      const state = location.state || {};
      navigate(state.from || "/", {replace:true, state});
    }
  }, [serverOffline]);

  setPageTitle("Plex Search Unavailable");
  return (
    <div className="app-container maintenance-page">
      <div className="logo-wrapper">
        <img src={logo} className="app-logo" alt="Plex Research" />
      </div>
      <div className="maintenance-content">
      <span>
        The server is currently unavailable.  Please try again later.
      </span>
      </div>
    </div>
  );
}

export default inject("appStatusStore")(observer(MaintenancePage));
