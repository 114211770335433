import React from "react";
import {compiler} from "markdown-to-jsx";
import {interpolate, InterpolatedProperty, interpolateProps, IPROP_PREFIX} from "./interpolation";
import {deriveTemplate} from "./index";
import {SafeRenderer} from "../components/SafeRenderer";
import OutboundLink from "../components/OutboundLink";
import {escapeMD, prepareMarkdown} from "./utils";


const isReactComponent = (componentType, templateOverrides = {}) => {
  if (typeof(componentType) === "function") {
    return true;
  }
  if (componentType == null) {
    return false;
  }
  if (templateOverrides[componentType]) {
    componentType = templateOverrides[componentType];
  }
  return typeof(componentType) !== 'string' && typeof(componentType.type) !== 'string';
}

export const createMarkdownElement = ({
                                        template,
                                        templateType = "no-template-type",
                                        overrides = {},
                                        entity = {},
                                        markText = null,
                                        errorMessage = null,
                                        prepare = true,
                                      }) => {

  // Designate properties passed to _every_ component within this element; this object gets augmented with interpolated
  // and formatted properties.  It also injects an "entity" prop into every component.
  const componentProps = {
    ...(overrides.props || {}),
    entity,
    markText,
  };
  const {forceDivParagraphs = true} = componentProps;

  // Maintain a list of all interpolation values to enable uniqueness checks
  const uniqueValues = new Set();
  // Use a custom createElement when compiling markdown so that we can inject
  // additional properties (entity and text marking) into React components generated from the markdown
  // See also https://github.com/quantizor/markdown-to-jsx/issues/479 w/r/t table => thead/tbody handling
  const createElement = (type, props, ...children) => {
    // You'd think that the 'forceBlock' param to the compiler would do this, but alas no...
    // Don't use "p" for paragraphs to avoid warnings on nested markdown
    // "div" not allowed within "p"
    const tag = typeof(type) === "function" ? type.name : type;
    if (type === "p" && forceDivParagraphs) {
      type = "div";
      if (!props.className) {
        props.className = "md-p"
      }
      else {
        props.className = `${props.className} md-p`
      }
    }
    children.forEach((child, index, array) => {
      if (child && typeof(child) !== 'string') {
        if (child.forEach) {
          child.forEach((subChild, subIndex, subArray) => {
            if (subChild && typeof(subChild) !== 'string') {
              const resolvedProps = interpolateChildProps(subChild.type, subChild.props, entity,
                                                          uniqueValues, componentProps, markText, overrides);
              subArray[subIndex] = React.cloneElement(subChild, resolvedProps, subChild.props?.children);
            }
          });
        }
        else {
          const resolvedProps = interpolateChildProps(child.type, child.props, entity,
                                                      uniqueValues, componentProps, markText, overrides);
          array[index] = React.cloneElement(child, resolvedProps, child.props?.children);
        }
      }
    });
    // Interpolated component-specific props override interpolated props
    const overrideProps = {...(overrides[tag]?.props || {}), ...componentProps};
    const resolvedProps = interpolateChildProps(type, props, entity,
                                                         uniqueValues, overrideProps, markText, overrides);
    return React.createElement(type, resolvedProps, ...children);
  };
  // The markdown compiler will inject override properties into components and ignore anything interpolated
  // in the markup.  Remove such override properties since interpolation has already set up placeholders and backing properties
  // Avoid modifying the original property settings.
  const updatedOverrides = Object.fromEntries(Object.entries(overrides).map(([k, v]) => {
    return [k, {...v, props: {...v.props}}];
  }));
  const interpolated = interpolate(template,
                                   entity,
                                   componentProps,
                                   markText,
                                   uniqueValues,
                                   updatedOverrides,
                                   escapeMD);

  //console.log(`Interpolation result\n${interpolated}\n`);
  const linkOverride = {a: ({href, ...props}) => OutboundLink({href: href, ...props})};
  const md = markText && interpolated === template
             ? `<div class="interpolated">${markText(interpolated, {asString: true})}</div>`
             : interpolated;
  // The following markdown compiler options seem to have no effect
  //forceBlock: true, forceWrapper: true, wrapper: 'markdown-wrapper',
  const options = {overrides: {InterpolatedProperty, ...linkOverride, ...updatedOverrides}, createElement};
  const compiled = compiler(prepare ? prepareMarkdown(md) : md, options);
  if (compiled == null) {
    // Nothing to render
    return null;
  }
  // Make all links open in a new tab
  const classNames = ["markdown", templateType];
  if (compiled.props.className) {
    classNames.push(compiled.props.className);
  }
  const className = classNames.join(" ");
  const element = React.cloneElement(compiled, {...compiled.props, ...(isReactComponent(compiled.type) ? componentProps : {}), className});
  return (
    <SafeRenderer
      className="markdown-safe-renderer"
      errorMessage={errorMessage || `Markdown render error ${element.type} template ${template}`}
      onError={(error, info) => console.error(`Markdown render error "${error}"`, {...entity, ...componentProps}, template)}
    >
      {element}
    </SafeRenderer>
  );
};

const interpolateChildProps = (type, props, entity, uniqueValues, componentProps, markText = null, templateOverrides = {}) => {
  if (!props) {
    props = {};
  }
  Object.entries(props).forEach(([key, value]) => {
    // Replace property placeholders
    if (typeof(value) === "string" && value.startsWith(IPROP_PREFIX)) {
      props[key] = componentProps[value];
    }
  });
  const isDOMElement = !isReactComponent(type);
  const interpolated = interpolateProps(props, entity, componentProps, uniqueValues, markText, templateOverrides);
  return Object.entries({...props, ...interpolated}).reduce((result, [key, value]) => {
    if (!key.startsWith(IPROP_PREFIX)) {
      result[key] = value;
    }
    return result;
  }, isDOMElement ? {} : {entity, markText});
};

export const renderMarkdownWithInterpolation = ({
                                                  templateType,
                                                  templates,
                                                  templateOverrides = {},
                                                  entity,
                                                  fallback = "",
                                                  markText = null,
                                                }) => {
  const template = deriveTemplate(templates, templateType, entity.category, entity.baseCategory, null, fallback);
  return createMarkdownElement({
                                 template: template,
                                 templateType,
                                 overrides: templateOverrides,
                                 entity,
                                 markText,
                               });
};

export const renderMarkdown = ({markdown = "", entity = {}, markText = null, overrides = {}, errorMessage = null, prepare = true}) => {
  try {
    return createMarkdownElement({
                                   template: markdown,
                                   templateType: "explicit-template",
                                   overrides: {...overrides, props: {...(overrides.props || {})}},
                                   entity, markText, errorMessage, prepare,
                                 });
  } catch (error) {
    console.error(error);
    return <span className="error-message error">{error.message}</span>;
  }
};
