import React, {Fragment, useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {Dropdown} from "react-bootstrap";
import NotificationCenter from "../modals/NotificationCenter";
import {flushCache} from "../../queries/info";
import {runInAction} from "mobx";
import {toast} from "react-toastify";
import {useNotificationCenter} from "react-toastify/addons/use-notification-center";

import {Threshold} from "./Threshold";
import Checkbox, {RadioButtonGroup} from "../controls/Checkbox";

// Use a slightly higher default than the server default
export const DEFAULT_CLIENT_SIM_THRESHOLD = 0.75;
// Default to pseudo-similarity on
export const DEFAULT_CLIENT_FIND_RELATED = true;
// For chem/sim
export const MIN_SIM_THRESHOLD = 0.55;
// For ECFP*
export const MIN_OTHER_THRESHOLD = 0.1;
const DEFAULT_COMPOUND_SIMTYPE = "sim";
const DEFAULT_MIN_EXPANSION = 5;

export const Group = ({className = null, title = null, children}) => (
  <>
    {title && (<Dropdown.Header>{title}</Dropdown.Header>)}
    <div className={`dropdown-group${className ? ' ' + className : ''}`}>
      {children}
    </div>
  </>
);

const SIM_DISABLE = "sim-disable"
const SIM_SUBSTRUCTURE = "sim-substructure"
const SIM_ENABLE = "sim-enable"

// To override default client settings, add a "local" property, and include its default value as a property
const SettingsMenu = ({
  className,
  searchSessionStore,
  appStatusStore,
  userStore,
  // If not provided, use the setting from the current search
  defaultClientSimThreshold = null,
  // If not provided, use the setting from the current search
  defaultFindRelated = null,
}) => {
  const {
    isAdmin = false,
    showAdmin = false,
    isSuperUser = false,
  } = userStore;
  const {
    ready: serverReady = false,
    versionInfo: {
      imaps_available: availableDsConfigs = [{name: "Loading...", datasets: {}, index: 0}],
      sim_methods_available: compoundSimMethodsAvailable = [],
    } = {},
  } = appStatusStore;
  const {
    clientSimThresholds,
    defaultSimThresholds,
    queuedSearchParams,
    modifySearchParameters,
  } = searchSessionStore;

  const {notifications, clear} = useNotificationCenter();

  const {
    dsConfig: spDsConfig = availableDsConfigs.length > 0 && availableDsConfigs[0].index || 0,
    queryEntities,
    simType,
    simMethod,
    simThreshold = null,
    simSubstructure,
    findRelated = defaultFindRelated,
    indirect,
    minExpansion,
  } = queuedSearchParams;
  const compoundSearch = queryEntities.some(el => el.category === "compound");
  const forceSubstructure = queryEntities.reduce((result, el) => result || el.id.startsWith("query:"), false);
  //console.debug(`sim thresholds ${JSON.stringify(clientSimThresholds)}`);
  const [showDropdown, setShowDropdown] = useState("");
  // Keep sim enabled/threshold state local
  const [simSetting, setSimSetting] = useState(simSubstructure || forceSubstructure
                                               ? SIM_SUBSTRUCTURE
                                               : simType !== ""
                                                 ? SIM_ENABLE : SIM_DISABLE);

  const [compoundSimType, setCompoundSimType] = useState(simType !== "default" ? simType : DEFAULT_COMPOUND_SIMTYPE);
  const [localMinExpansion, setLocalMinExpansion] = useState(minExpansion || DEFAULT_MIN_EXPANSION);
  const [localSimThresholds, setLocalSimThresholds] = useState(
    {
      ...defaultSimThresholds,
      ...clientSimThresholds,
      // Override if we've been given a different default value
      "sim": defaultClientSimThreshold || clientSimThresholds["sim"] || simThreshold,
      "chem": defaultClientSimThreshold || clientSimThresholds["chem"] || simThreshold,
    });
  const [localFindRelated, setLocalFindRelated] = useState(compoundSearch ? findRelated : defaultFindRelated || findRelated);

  useEffect(() => {
    modifySearchParameters({findRelated: localFindRelated});
  }, [localFindRelated]);

  useEffect(() => {
    // Turn off find related by default if doing a compound search; do not, however, disable it.
    if (compoundSearch) {
      setLocalFindRelated(false);
    }
  }, [compoundSearch]);

  useEffect(() => {
    if (simSubstructure || forceSubstructure) {
      setSimSetting(SIM_SUBSTRUCTURE)
    }
    else if (simType !== "") {
      setSimSetting(SIM_ENABLE);
    }
    else {
      setSimSetting(SIM_DISABLE);
    }
  }, [simSubstructure, simType]);

  useEffect(() => {
    if (minExpansion) {
      setLocalMinExpansion(minExpansion);
    }
  }, [minExpansion]);

  useEffect(() => {
    runInAction(() => {
      ["sim", "chem"].forEach(key => {
        const value = defaultClientSimThreshold || clientSimThresholds[key] || defaultSimThresholds[key];
        if (value) {
          clientSimThresholds[key] = value;
        }
      });
    });
  }, [defaultClientSimThreshold]);

  useEffect(() => {
    if (!simThreshold || simThreshold === clientSimThresholds[compoundSimType]
        || clientSimThresholds[compoundSimType] !== defaultSimThresholds[compoundSimType]
        || !localSimThresholds[compoundSimType]) {
      localSimThresholds[compoundSimType] = defaultClientSimThreshold;
    }
  }, [clientSimThresholds, compoundSimType, defaultClientSimThreshold, simThreshold])

  useEffect(() => {
    if (simType === undefined || simType === "default") {
      setCompoundSimType(DEFAULT_COMPOUND_SIMTYPE);
    }
    else if (!new Set(["", "set", "org"]).has(simType)) {
      setCompoundSimType(simType);
    }
  }, [simType]);

  useEffect(() => {
    if (simSetting === SIM_ENABLE) {
      const threshold = simThreshold || clientSimThresholds[compoundSimType] || defaultSimThresholds[compoundSimType]
      modifySearchParameters({
                               simType: compoundSimType,
                               simSubstructure: false,
                               ...(threshold ? {simThreshold: threshold} : {}),
                             });
    }
    else if (simSetting === SIM_SUBSTRUCTURE) {
      modifySearchParameters({
                               simType: compoundSimType,
                               simSubstructure: true,
                             });
    }
    else {
      modifySearchParameters({
                               simType: "",
                               simThreshold: null,
                               simSubstructure: false,
                             });
    }
  }, [simSetting, simThreshold, compoundSimType]);

  const switchCompoundSimType = (key) => {
    if (compoundSimType !== key) {
      setCompoundSimType(key);
      modifySearchParameters({simType: key});
    }
  };

  const handleMinExpansionChange = (key, value) => {
    setLocalMinExpansion(value);
    setLocalSimThresholds({...localSimThresholds, key: clientSimThresholds[key]})
    // Preserve the local setting, but remove any queued simThreshold parameter
    runInAction(() => {
      clientSimThresholds[key] = defaultSimThresholds[key];
    });
    modifySearchParameters({minExpansion: value, simThreshold: null});
  }

  const handleSimThresholdChange = (key, value) => {
    if (clientSimThresholds[key] !== value) {
      runInAction(() => {
        clientSimThresholds[key] = localSimThresholds[key] = value;
      });
    }
    modifySearchParameters({minExpansion: null, simThreshold: value});
  };

  const handleSimMethodChange = (key) => {
    modifySearchParameters({simMethod: key});
  };
  const toggleFindRelated = () => {
    setLocalFindRelated(!localFindRelated && !compoundSearch);
  }
  const toggleIndirect = () => {
    modifySearchParameters({indirect: !indirect});
  }
  const showDSConfigs = availableDsConfigs.length > 1 || isAdmin;
  const cn = `${className ? ' ' + className : ''}${serverReady ? '' : ' icon-pulse'}`;
  const {
    compoundFPTypes
  } = appStatusStore;
  const fpTypes = new Set(compoundFPTypes);

  return (
    <span title="Settings" className="settings-menu">
      <Dropdown
        title="Settings"
        className={`settings-menu${cn}`}
        key="settings"
        autoClose="outside"
        disabled={!serverReady}
        focusFirstItemOnShow={true}
        onToggle={(isOpen) => setShowDropdown(isOpen)}
        show={showDropdown}
      >
        <Dropdown.Toggle disabled={!serverReady}><span className="glyphicon glyphicon-cog" /></Dropdown.Toggle>
        <Dropdown.Menu rootCloseEvent="click">
          {showDSConfigs && (
            <>
              <Dropdown.Item>
                <label>
                  Dataset Configuration
                  <select
                    disabled={availableDsConfigs.length === 1}
                    title={`${availableDsConfigs.reduce((result, item) => {
                      if (item.index === spDsConfig) {
                        return (item.datasets || []).join(", ");
                      }
                      return result;
                    }, "")}`}
                    value={spDsConfig}
                    onChange={e=> modifySearchParameters({dsConfig: e.target.value})}
                  >
                    {availableDsConfigs.map((item) => (
                      <option
                        key={item.index}
                        value={item.index}
                        title={(item.datasets || []).join(", ")}
                        >
                        {`${item.name} (${item.version})`}
                      </option>
                    ))}
                  </select>
                </label>
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item
            onClick={toggleFindRelated}
            title={"Include the starting category in the search results to find commonality"}
          >
            <Checkbox
              checked={localFindRelated}
              label={"Find Related"}
              title={"Include the starting category in the search results to find commonality"}
              onChange={toggleFindRelated}
            />
          </Dropdown.Item>
          {isAdmin && (
            <Dropdown.Item
              className={"admin"}
              onClick={toggleIndirect}
              title={"Restrict search results to those without a direct connection to the query"}
            >
              <Checkbox
                checked={indirect}
                label={"Indirect"}
                title={"Restrict search results to those without a direct connection to the query"}
                onChange={toggleIndirect}
                />
            </Dropdown.Item>)}
          <Dropdown.Divider/>
          <RadioButtonGroup disabled={forceSubstructure} legend={""}>
            <Dropdown.Item
              onClick={() => {setSimSetting(SIM_DISABLE)}}
              disabled={forceSubstructure}
              title="Disable similarity"
            >
              <Checkbox
                checkType={"radio"}
                checked={simSetting === SIM_DISABLE}
                label={"Disable Similarity"}
                title="Disable similarity"
                disabled={forceSubstructure}
                onChange={() => {setSimSetting(SIM_DISABLE)}}
                />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setSimSetting(SIM_SUBSTRUCTURE)}
              disabled={forceSubstructure}
              title="Include compounds for which the query is a substructure"
            >
              <Checkbox
                checkType={"radio"}
                checked={simSetting === SIM_SUBSTRUCTURE}
                disabled={forceSubstructure}
                label={"Match Compound Substructure"}
                title="Include compounds for which the query is a substructure"
                onChange={() => setSimSetting(SIM_SUBSTRUCTURE)}
                />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setSimSetting(SIM_ENABLE)}
              disabled={forceSubstructure}
              title="Include compounds similar to the query"
            >
              <Checkbox
                checkType={"radio"}
                checked={simSetting === SIM_ENABLE}
                disabled={forceSubstructure}
                label={"Use Compound Similarity"}
                title="Include compounds similar to the query"
                onChange={() => setSimSetting(SIM_ENABLE)}
              />
            </Dropdown.Item>
          </RadioButtonGroup>
          <Group className={simSetting === SIM_DISABLE ? "disabled" : ""}>
            <RadioButtonGroup
              legendClassName="dropdown-header"
              legend={"Similarity Extent"}
              title={"Determine when to stop checking compounds of lower similarity"}
            >
              <Threshold
                label={"Target Threshold"}
                title={"Automatically include decreasingly similar compounds until at least this many targets have been found (down to usimilarity threshold)"}
                checkType={"radio"}
                isSelected={minExpansion != null}
                value={localMinExpansion}
                onToggle={checked => checked && handleMinExpansionChange(compoundSimType, localMinExpansion)}
                onValueChange={value => handleMinExpansionChange(compoundSimType, value)}
                disabled={simSetting !== SIM_ENABLE}
                min={1} max={10000} step={1} precision={null}
              />
              <Threshold
                label={"Similarity Threshold"}
                title={"Minimum similarity score to use when searching"}
                checkType={"radio"}
                isSelected={minExpansion == null}
                value={localSimThresholds[compoundSimType] || simThreshold || DEFAULT_CLIENT_SIM_THRESHOLD}
                onToggle={checked => checked && handleSimThresholdChange(compoundSimType, clientSimThresholds[compoundSimType])}
                onValueChange={value => handleSimThresholdChange(compoundSimType, value)}
                disabled={simSetting !== SIM_ENABLE}
                thresholdDisabled={simSetting === SIM_SUBSTRUCTURE}
                min={compoundSimType === "" || compoundSimType === "" ? MIN_SIM_THRESHOLD : MIN_OTHER_THRESHOLD}
                max={1} step={0.05} precision={2}
              />
            </RadioButtonGroup>
            <Dropdown.Item
              title={"Use the selected fingerprint type"}
              disabled={simSetting === SIM_DISABLE}
            >
              <label>Fingerprint Type
                <select
                  value={compoundSimType}
                  onChange={e => switchCompoundSimType(e.target.value)}
                  disabled={simSetting === SIM_DISABLE}
                >
                  {Array.from(fpTypes).sort().map((key) => (
                    <option
                      key={key}
                      value={key}
                    >
                      {key}
                    </option>
                  ))}
                </select>
              </label>
            </Dropdown.Item>
            <Dropdown.Item
              title={"Use the selected measure for fingerprint similarity"}
              disabled={simSetting !== SIM_ENABLE}
            >
              <label>Measure
                <select
                  value={simMethod}
                  disabled={simSetting !== SIM_ENABLE}
                  onChange={e => handleSimMethodChange(e.target.value)}
                >
                  {compoundSimMethodsAvailable.map((key) => (
                    <option
                      key={key}
                      value={key}
                    >
                      {key}
                    </option>
                  ))}
                </select>
              </label>
            </Dropdown.Item>
            <Threshold
              className={"admin"}
              label={"Gene/Compound List"}
              title={"Threshold for measuring similarity between two lists of the same type (e.g. RNA Profiles or Pathways)"}
              value={clientSimThresholds['set']}
              onValueChange={value => handleSimThresholdChange("set", value)}
              onEnter={() => setShowDropdown(false)}
              disabled={simSetting !== SIM_ENABLE}
              min={0} max={1} step={0.05} precision={2}
            />
          </Group>
          {isAdmin && (
            <>
              <Dropdown.Divider className="admin" />
              <Dropdown.Item className={"admin-toggle"} >
                <Checkbox
                  label={"Show Admin Settings"}
                  title={`Select to make more admin settings visible`}
                  checked={showAdmin}
                  onChange={s => userStore.showAdmin = s}
                />
              </Dropdown.Item>
              <Dropdown.Item
                className="admin"
                title="Remove any previous search results from the cache"
                onClick={()=>{flushCache(); setShowDropdown(false)}}
                key="flush"
              >
                Flush Cache
              </Dropdown.Item>
              <Dropdown.Item
                className="admin"
                title="Generate a warning message"
                onClick={() => {setShowDropdown(false);toast.warning("This is a warning");}}
                key="toast-warning"
              >
                Toast Warning
              </Dropdown.Item>
              <Dropdown.Item
                className="admin"
                title="Generate an error message"
                onClick={() => {setShowDropdown(false);toast.error("This is an error");}}
                key="toast-error"
              >
                Toast Error
              </Dropdown.Item>
              <Dropdown.Item
                className="admin"
                title="Generate an unhandled program error"
                onClick={() => {setShowDropdown(false); throw Error("Sentry test uncaught exception")}}
                key="sentry-uncaught-exception"
              >
                Test Sentry (uncaught exception)
              </Dropdown.Item>
              <Dropdown.Item
                className="admin"
                title="Generate an unhandled program error within a promise"
                onClick={() => {
                  setShowDropdown(false);
                  fetch("/").then(() => {
                    throw Error("Sentry test uncaught exception within promise")
                  });
                }}
                key="sentry-uncaught-promise-exception"
              >
                Test Sentry (promise w/unhandled exception)
              </Dropdown.Item>
              <Dropdown.Item
                className="admin"
                title="Generate a console warning"
                onClick={() => {setShowDropdown(false); console.warn("Sentry console warning")}}
                key="sentry-console-warning"
              >
                Test Sentry (console warning)
              </Dropdown.Item>
              <Dropdown.Item
                className="admin"
                title="Generate a console error"
                onClick={() => {setShowDropdown(false); console.error("Sentry console error")}}
                key="sentry-console-error"
              >
                Test Sentry (console error)
              </Dropdown.Item>
            </>
          )}
          <NotificationCenter notifications={notifications} clearNotifications={clear}>
            {({ showModal }) => (
              <>
                <Dropdown.Divider />
                <Dropdown.Item
                  key={"messages"}
                  title={notifications?.length ? "Show system message history and details" : "No system messages"}
                  disabled={!notifications?.length}
                  onClick={() => {if (notifications?.length) {setShowDropdown(false); showModal()}}}
                >
                  {notifications.length ? `Show messages (${notifications?.length})` : "No system messages"}
                </Dropdown.Item>
              </>
            )}
          </NotificationCenter>
        </Dropdown.Menu>
      </Dropdown>
    </span>
  );
};

export default inject("searchSessionStore", "userStore", "appStatusStore")(observer(SettingsMenu));
