import React, {useState, useEffect} from "react";
import {useNavigate, useLocation} from "react-router";
import Registration from "./Registration.jsx";
import FormLayout from "../FormLayout";
import {
  getMessageObject,
  MessageType,
  MessageObject
} from "../FormLayout/MessageFactory";
import {
  validateUsername,
  validatePassword,
  validateForm
} from "../validation";
import { inject, observer } from "mobx-react";

const RegistrationContainer = (props) => {

  const {userStore} = props;
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const status = parseInt(params.get('confirmationStatus') || 0, 10) || 0;

  const [formErrors, setFormErrors] = useState({});
  const [username, setUsername] = useState(location.state?.username);
  const [password, setPassword] = useState(location.state?.password);
  const [passwordCheck, setPasswordCheck] = useState("");
  const [messageObject, setMessageObject] = useState(
    status === 1
      ? getMessageObject(MessageType.UserNotFoundException, username)
      : status === 2
        ? getMessageObject(MessageType.UserNotConfirmedException)
        : status === 3
          ? getMessageObject(MessageType.LinkExpired, username)
          : status === 4
            ? getMessageObject(MessageType.ConfirmationFailed, username)
            : status === 5
              ? getMessageObject(MessageType.NotAuthorized, username)
              : null
  );
  const [showWarnings, setShowWarnings] = useState({
                                                     username: username !== "",
                                                     password: password !== "",
                                                     passwordCheck: false
                                                   });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const resendConfirmationLink = (message = null, password = null) => {
    userStore
      .resendConfirmation(username)
      .then(result => {
        navigate("/signin", {
          replace: true,
          state: {
            username: username,
            message: message || getMessageObject(MessageType.ConfirmAccount, username)
          },
        });
      })
      .catch(error => {
        if (error.name === "UserNotFoundException") {
          handleRegistration();
        } else if (error.name === "LimitExceededException") {
          navigate("/signin", {
            replace: true,
            state: {
              username: username,
              message: MessageObject("Too many retries, try again later", "error"),
            },
          });
        } else if (error.name === "InvalidParameterException") {
          // This may happen if the user is already confirmed and tries to re-use a confirmation link
          // Track the error just in case there's some other reason
          navigate("/signin", {
            replace: true,
            state: {
              username: username,
              password: password || "",
              message: getMessageObject(MessageType.AlreadyConfirmed, username)
            },
          });
        } else {
          setMessageObject(MessageObject(error.message, "error", error.name));
        }
      });
  };

  const handleRegistration = () => {
    setFormSubmitted(true);
    userStore
      .register(username, password)
      .then(result => {
        navigate("/signin", {
          replace: true,
          username: username,
          state: {message: getMessageObject(MessageType.ConfirmAccount, username)}
        });
      })
      .catch(error => {
        if (error.name === "UsernameExistsException") {
          // The user is either unconfirmed or already registered
          // Attempt a confirmation re-send in either case, then switch to the login page
          resendConfirmationLink(getMessageObject(MessageType.NewConfirmationLink, username), password);
        } else {
          let errorMsg = error.message;
          if (error.name === "UserLambdaValidationException") {
            if (error.message.indexOf("unauthorized") === -1) {
              errorMsg = `The user ${username} is not authorized to use this system`;
            }
            else {
              errorMsg = null;
            }
          }
          // NOTE: error for registered and unconfirmed users is the same
          setFormSubmitted(false);
          setMessageObject(getMessageObject(MessageType.NotAuthorized, errorMsg));
        }
      });
  };

  const handleChange = field => evt => {
    setMessageObject(null);
    const value = evt.target.value;
    if (field === "username") {
      setUsername(value.trim());
    }
    else if (field === "password") {
      setPassword(value);
    }
    else if (field === "passwordCheck") {
      setPasswordCheck(value);
    }
  };

  // Install this as an onFocus handler if you want any error messages to be cleared
  // when the user is using the field
  const handleFocus = (field, focused) => evt => {
    setShowWarnings({ ...showWarnings, [field]: !focused });
  };

  useEffect(() => {
    const errors = validateForm(username, password, passwordCheck);
    const errorMsg = {
      type: "error",
      name: "error",
      message: ""
    };
    ['username', 'password', 'passwordCheck'].forEach(x => {
      if (showWarnings[x] && errors[x]) {
        errorMsg.message += (messageObject?.message ? "\n" : "") + errors[x];
      }
    });
    setMessageObject(errorMsg.message !== "" ? errorMsg : null);
    setFormErrors(errors);
  }, [username, password, passwordCheck]);

  // ??
  window.name = "plex";
  return (
    <FormLayout message={messageObject}>
      <Registration
        errors={formErrors}
        username={username}
        password={password}
        passwordCheck={passwordCheck}
        onChange={handleChange}
        onRegister={handleRegistration}
        onSwitchToSignin={() => navigate("/signin", {replace:true, state: {from: location}})}
        onFocus={handleFocus}
        formSubmitted={formSubmitted}
      />
    </FormLayout>
  );
};

export default inject("userStore")(observer(RegistrationContainer));
export { validatePassword, validateUsername, validateForm };
