import {useEffect, useRef, useState} from "react";
import {oneshot} from "../queries/autosuggest";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import "reveal.js/dist/theme/white.css";
import RevealMarkdown from "reveal.js/plugin/markdown/markdown";
import {DEV} from "../lib/configMgr";
import DownloadButton from "../components/DownloadButton";
import contentDisposition from "content-disposition";
import {saveBlobContent} from "../lib/utils";
import {toast} from "react-toastify";

export const TEXT_PLACEHOLDER = "--TEXT-PLACEHOLDER--";

export const MD_PROMPT = `
You are a presentation creation assistant. Your task is to summarize the given text and create Reveal.js markdown for a 
presentation. Follow these guidelines:
 <guidelines>
 Analyze the input text and identify 3-5 key points or themes.
For each key point, create a separate slide using Reveal.js markdown.
Keep each slide concise, with bullet points where appropriate.
Use markdown "---" to divide each slide.
If there are any important quotes, include them using blockquote markdown.
For any numerical data, consider using markdown lists.
Include a title slide at the beginning and a summary slide at the end.
 </guidelines>
Here's the text to summarize and convert into a Reveal.js presentation:
 ${TEXT_PLACEHOLDER}
Generate the markdown for a presentation based on this text. Each slide should be informative yet concise,
capturing the essence of the original content.
Return only the markup, without any surrounding text or explanation.
`;

export const RevealSlideShow = ({markdown}) => {
  const deckRef = useRef();
  const revealRef = useRef();
  useEffect(() => {
    if (deckRef.current) {
      const deck = revealRef.current = new Reveal(deckRef.current, {
        transition: "slide",
        embedded: true,
        plugins: [RevealMarkdown],
      });
      deck.initialize();
      return () => {
        deck?.destroy();
      }
    }
  }, []);
  return (
    <div className={"reveal"} ref={deckRef}>
      <div className={"slides"}>
        <section data-markdown={""}>
          <textarea data-template={""} defaultValue={markdown} />
        </section>
      </div>
    </div>
  );
};

export const SlidesSandbox = (props = {}) => {
  const {userStore: authData} = props;
  const [prompt, setPrompt] = useState(localStorage['prompt'] || MD_PROMPT);
  const [inputText, setInputText] = useState(localStorage['slidesInput'] || '');
  const [slidesMarkdown, setSlidesMarkdown] = useState(localStorage['slidesMarkdown'] || '');
  const [isDownloading, setIsDownloading] = useState(false);
  const [generatingSlides, setGeneratingSlides] = useState(false);

  const generateSlides = () => {
    setGeneratingSlides(true)
    oneshot(prompt.replace(TEXT_PLACEHOLDER, `<input-text>${inputText}</input-text>`))
      .then(json => {
        setSlidesMarkdown(JSON.parse(json));
      })
      .catch(e => {
        console.error(`Slides gen failed ${e}`);
      })
    .finally(() => setGeneratingSlides(false));
  };

  const downloadSlides = () => {
    setIsDownloading(true);
    let filename = null;
    const options = {
      input_format: "markdown",
      output_format: "pptx",
      mime_type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    }
    oneshot(slidesMarkdown, authData, options)
      .then(response => {
        const header = response.headers.get('content-disposition');
        const disposition = typeof header === "string" ? contentDisposition.parse(header) : null;
        filename = disposition ? disposition.parameters.filename : `summary.pptx`;
        return response.blob();
      })
      .then(blob => {
        saveBlobContent(blob, filename);
      })
      .catch(e => {
        console.error("Summary generation failed", e);
        toast.error("Summary generation failed");
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  useEffect(() => {
    localStorage["slidesPrompt"] = prompt;
  }, [prompt]);

  useEffect(() => {
    localStorage["slidesInput"] = inputText;
  }, [inputText]);

  useEffect(() => {
    localStorage["slidesMarkdown"] = slidesMarkdown;
  }, [slidesMarkdown]);

  return (
    <div className={"slide-generator"}>
      <div className={"two-columns"}>
        {DEV && (<textarea
          className={"prompt"}
          placeholder={"LLM prompt"}
          value={prompt}
          onChange={e => setPrompt(e.target.value)}/>)}
        <textarea
          className={"input-text"}
          placeholder={"Source text"}
          value={inputText}
          onChange={e => setInputText(e.target.value)}/>
      </div>
      <div>
        <button
          className={generatingSlides ? "icon-pulse" : ""}
          onClick={() => generateSlides()}
          disabled={!inputText || generatingSlides}
        >
          Generate Slides Markdown
        </button>
        <DownloadButton
          className={`download-ppt${isDownloading ? " icon-pulse" : ""}`}
          glyphicon={""}
          title={isDownloading ? "Downloading Summary" : "Download Slides"}
          onClick={() => downloadSlides()}
          disabled={!inputText || isDownloading}
        />
      </div>
      <p><label>Generated Markdown</label></p>
      <div className={"slides-markdown"}>
        <textarea
          onChange={(e) => setSlidesMarkdown(e.target.value)}
          value={slidesMarkdown} />
      </div>
      <p><label>Preview</label></p>
      <div className={"slideshow"}>
        <RevealSlideShow markdown={slidesMarkdown} key={slidesMarkdown} />
      </div>
    </div>
  );
};
