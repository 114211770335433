import React, {useCallback, useEffect, useRef, useState} from "react";
import {renderMarkdown} from "../../markdown/markdown-factory";
import AnimateHeight from "react-animate-height";
import {endRegexEval, startRegexEval} from "../../lib/utils";

const CollapsibleText = ({
                           className = null,
                           isOpen: controllerOpen = false,
                           onToggle : controllerToggle = null,
                           text = "",
                           visibleChars = 160,
                           minimumHidden = 80,
                           markText = null,
                         }) => {

  const [isOpen, setIsOpen] = useState(controllerOpen);
  const textRef = useRef();
  const [hasOverflow, setHasOverflow] = useState(false);
  const [height, setHeight] = useState('auto');
  const onToggle = controllerToggle || (() => setIsOpen(!isOpen));
  let forceOpen = false;

  useEffect(() => {
    setIsOpen(controllerOpen);
  }, [controllerOpen, controllerToggle]);

  const s = text;
  try {
    startRegexEval(s, "CollapsibleText");
    text = text.replace(/(\s*<br\/>\s*)+/gi, "\n").trim().replace(/\n/gi, "<br/>");
    if (text && /\w/.test(text[text.length - 1])) {
      text += ".";
    }
  }
  finally {
    endRegexEval(s);
  }

  if (markText) {
    const marked = markText(text);
    if (marked !== text || isOpen) {
      forceOpen = true;
    }
  }

  const onMount = useCallback(node => {
    if (node != null) {
      textRef.current = node;
      if (!isOpen && !forceOpen) {
        // Only sample the height when not expanded
        if (node.scrollHeight > node.clientHeight) {
          setHasOverflow(true);
        }
      }
    }
  }, [isOpen, forceOpen]);

  const rendered = renderMarkdown({markdown: text, markText});
  useEffect(() => {
    if (textRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setHeight(textRef.current.clientHeight);
      });
      resizeObserver.observe(textRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  const showToggle = hasOverflow && !forceOpen;

  return (
    <span className={`collapsible-text collapsible${className ? ' ' + className : ''}`}>
      {text.length === 0 ? null : (
        <>
          <AnimateHeight
            duration={500}
            disableDisplayNone
            height={height}>
            <span key="text-content"
                  ref={onMount}
                  className={`${isOpen || forceOpen ? "expanded" : "collapsed"}${hasOverflow ? " with-overflow" : ""}`}>
              {rendered}
            </span>
          </AnimateHeight>
          {showToggle && (
            <span key="toggle"
                  className={`collapse-toggler`}
                  onClick={onToggle}>
              {isOpen || forceOpen ? "less" : "more"}
            </span>
          )}
        </>
      )}
    </span>
);
};

export default CollapsibleText;
