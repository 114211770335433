import React from "react";
import "./styles.css";

const TemplateEditor = ({ initText, onTemplateChange }) => {
  return (
      <textarea
        className="template-editor"
        value={initText}
        onChange={x => onTemplateChange(x.target.value)}
      />
  );
};

export default TemplateEditor;
