import React, {useEffect, useState} from "react";
import VisiblePart from "./VisiblePart";
import CollapsiblePart from "./CollapsiblePart";
import * as PropTypes from "prop-types";
import {toJS} from "mobx";
import {naturalSort} from "../../../lib/utils";

const CollapsibleList = ({collapsingListRef, isOpen, onToggle, list, visibleNum, minimumHidden = 3, markText = null}) => {

  const compare = (a, b) => {
    if (a.map && b.map) {
      // Special case compound activities
      return compare(a[0], b[0]);
    }
    return naturalSort(a, b);
  };

  const visibleCount = list.length - visibleNum < minimumHidden ? list.length : visibleNum;

  const [sortedList, setSortedList] = useState(toJS(list).sort(compare));
  const [visibleItems, setVisibleItems] = useState(sortedList.slice(0, visibleCount));
  const [hiddenItems, setHiddenItems] = useState(sortedList.slice(visibleCount));

  useEffect(() => {
    setSortedList(toJS([...list]).sort(compare))
  }, [list]);

  useEffect(() => {
    setVisibleItems(sortedList.slice(0, visibleCount));
    setHiddenItems(sortedList.slice(visibleCount));
  }, [sortedList, visibleCount]);

  return (
    <span className="collapsible-list collapsible">
      <VisiblePart list={visibleItems} markText={markText} />
      {hiddenItems.length ? (<CollapsiblePart
        listRef={collapsingListRef}
        list={hiddenItems}
        isOpen={isOpen}
        markText={markText}
        onToggle={onToggle}
      />) : null}
    </span>
  );
};

CollapsibleList.propTypes = {
  list: PropTypes.array.isRequired,
  visibleNum: PropTypes.number,
  minimumHidden: PropTypes.number,
  markText: PropTypes.func,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CollapsibleList;
