export class NotAuthorized extends Error {
}

export class ServerError {
  constructor(error = {message: "Server error"}, status = null) {
    if (typeof(error) === "string") {
      error = new Error(error);
    }
    this.message = (error?.message?.indexOf("Failed to fetch") || -1) !== -1 ? "Connection failed" : error.message;
    this.name = this.constructor.name;
    this.stack = error.stack;
    if (status) {
      this.status = status;
    }
  }

  toString = () => {
    return this.message;
  }
}

export class SearchError extends ServerError {
  constructor(error = "Search failed") {
    super(error);
  }
}

export class InvalidSearchParameters extends SearchError {
  constructor(params = {}, error = `Invalid search parameters ${JSON.stringify(params)}`) {
    super(error);
    this.params = params;
  }
}

export class SearchCanceled extends SearchError {
  constructor(error = "Search canceled") {
    super(error);
  }
}

export class SearchTimeoutError extends SearchError {
  constructor(error = "Search timed out\n"
    + "If you searched with a list of items, please shorten the list and try again\n"
    + "If using compound similarity, raise the similarity threshold") {
    super(error);
  }
}

export class ServerOfflineError extends ServerError {
  constructor(error = "Plex search is currently unavailable, please try again later") {
    super(error);
  }
}

export class ServerStartingException extends ServerError {
  constructor(error = "Server starting up") {
    super(error);
  }
}
