import {inject, observer} from "mobx-react";
import {Image, Modal} from "react-bootstrap";
import logo from "../../img/official_logo.png";
import ChatSession, {ROLE_ANALYST} from "../ChatSession";
import {Tooltip} from "react-tooltip";

// Chat session in context of a specific search
export const ChatModal = ({userStore, appStatusStore, isOpen, onHide, ...chatProps}) => {

  return (
    <Modal
      show={isOpen}
      onHide={onHide}
      className="top-level-modal"
      dialogClassName="ai-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Image className="logo" src={logo} />
          <span className="title">Large Language Model (LLM) Interface</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body autoFocus={true} >
        <ChatSession
          useTools={true}
          allowAttachments={true}
          showWelcome={true}
          userStore={userStore}
          appStatusStore={appStatusStore}
          assistantRole={ROLE_ANALYST}
          {...chatProps}
          tooltipID={"mtt"}
        />
      </Modal.Body>
      <Tooltip id="mtt"/>
    </Modal>
  )
};

export default inject("userStore", "appStatusStore")(observer(ChatModal));
