import React, {useEffect, useRef, useState} from "react";
import logo from "../img/official_logo.png";
import SearchBox from "../components/SearchBox";
import { inject, observer } from "mobx-react";

import {setPageTitle} from "../lib/utils";
import ChatSession from "../components/ChatSession";
import {DEV, getConfig} from "../lib/configMgr";

const {withAutoplex} = getConfig()

const LandingPage = ({searchSessionStore: {clear: clearSearch, fetchSuggestions, fetchEntities}, appStatusStore, userStore}) => {

  const [conversationActive, setConversationActive] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const {aiModels = {}} = appStatusStore;
  const aiAvailable = Object.keys(aiModels || {}).length > 0;
  if (!aiAvailable) {
    console.warn("No working LLM configuration found");
  }
  else if (DEV) {
    console.log("AI models", aiModels);
  }

  useEffect(() => {
    // Cancel/clear any extant search when page first loads
    clearSearch();
  }, []);
  useEffect(() => {
    setPageTitle();
  }, []);

  return (
    <div className={`app-container`}>
      <div className="logo-wrapper">
        <img tabIndex={0} src={logo}
             className={`app-logo${showChat && conversationActive ? " small" : ""}`}
             alt="Plex Research"
             />
      </div>
      <div className={`landing-page-inputs`}>
        {showChat && aiAvailable ? (
          <ChatSession
            autoFocus={true}
            userStore={userStore}
            showSettings={false}
            appStatusStore={appStatusStore}
            onSearchClick={() => setShowChat(false)}
            onConversationActive={active => setConversationActive(active)}
            useTools={withAutoplex}
            allowAttachments={withAutoplex}
            fetchEntities={fetchEntities}
            fetchSuggestions={fetchSuggestions}
          />
        ) : (
          <SearchBox withAutoplex={withAutoplex} aiAvailable={aiAvailable} onAIClick={() => setShowChat(true)} />
        )}
      </div>
    </div>
  );
}

export default inject("searchSessionStore", "appStatusStore", "userStore")(observer(LandingPage));
