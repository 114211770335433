import {getConfig} from "./configMgr";
import {RequestBuilder} from "../queries/request";
import {InvalidSearchParameters} from "../queries/errors";
import {toast} from "react-toastify";
import {extractLabel, getCustomLabel, saveCustomLabel, updateLegacyIDs} from "./utils";

// TODO: much of this module could replaced by an OpenAPI/swagger library
const { MAX_URL_LENGTH } = getConfig();

const SEARCH_PAGE = "search";

// Convert URL params into internal JS params which don't fit conversion pattern
// Include _only_ those params used in the URL, _not_ all API params
const API2JS = {
  "ids": "searchIDs",
  "imap": "dsConfig",
  "search_id": "searchID",
  "categories": "useCategories",
};
// Convert from JS to URL param which don't fit conversion pattern
const JS2API = {
  "searchIDs": "ids",
  "searchID": "search_id",
  "queryEntities": "ids",
  "dsConfig": "imap",
  "useCategories": "categories",
}
const INTPARAMS = new Set(["limit", "neighborhood", "imap", "min_expansion"]);
const FLOATPARAMS = new Set(["sim_threshold"]);
const BOOLPARAMS = new Set(["indirect", "find_related", "sim_substructure"]);
// This is probably superfluous, since they're not likely to be included in the queryStr to begin with
const IGNOREPARAMS = new Set([
                               "export_format", "datasets_filter", "category", "target_id",
                               "model", "snapshot_size"
                             ]);

export const convertQueryParamNames = (params) => {
  return Object.entries(params).reduce((result, [key, value]) => {
    if (JS2API[key]) {
      if (key === "queryEntities") {
        value = value.map(el => el.id);
      }
      key = JS2API[key];
    }
    else {
      key = key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
    }
    result[key] = value;
    return result;
  }, {});
};

export const constructSearchAPIRequest = (baseURL, params, forcePOST = false, streamProgress = false) => {
  // SSE is disabled by default until we've tested w/potential AWS/cloudfront timeouts or other blocking behavior
  // TODO: remove any non-applicable sim parameters
  const queryParams = convertQueryParamNames(params);
  const url = SearchContext.getAppSearchURL(queryParams, {}, {baseURL});
  if (url == null) {
    throw new InvalidSearchParameters(queryParams);
  }
  if (streamProgress) {
    url.searchParams.set('stream_progress', true);
  }
  if (!forcePOST && url.toString().length < MAX_URL_LENGTH
      && !queryParams.labels
      && !queryParams.messages
      && !queryParams.templates) {
    return new RequestBuilder(url);
  }
  url.searchParams.delete('ids');
  const body = {
    ids: updateLegacyIDs(queryParams.ids || []),
  }
  if (queryParams.messages) {
    url.searchParams.delete('messages');
    body.messages = queryParams.messages;
  }
  if (queryParams.templates) {
    url.searchParams.delete('templates');
    body.templates = queryParams.templates;
  }
  if (queryParams.labels) {
    url.searchParams.delete('labels');
    body.labels = queryParams.labels;
  }
  return new RequestBuilder(url).asPOST(JSON.stringify(body));
};

class SearchContext {
  // Given parameters in either client or server-side format, generate an appropriate URL
  static getAppSearchURL = (params, localParams = {}, config = {}) => {
    const {baseURL = `/${SEARCH_PAGE}`, withLabels = false} = config;
    if (params.searchIDs || params.queryEntities) {
      params = convertQueryParamNames(params);
    }
    if (!params.ids?.length) {
      if (!params.messages) {
        return null;
      }
    }
    else {
      params.ids = params.ids.map(id => {
        if (withLabels) {
          const label = getCustomLabel(id);
          if (label) {
            return `${id} label=${label}`;
          }
        }
        return id;
      });
    }
    const url = new URL(baseURL, window.location.origin);
    Object.entries(params).forEach(([name, value]) => {
      if (value != null) {
        if (value && typeof(value) !== 'string' && value.length) {
          value.forEach(element => {
            url.searchParams.append(name, element);
          });
        }
        else {
          url.searchParams.set(name, value);
        }
      }
    });
    const hashParams = new URLSearchParams(localParams);
    if (url.toString().length > MAX_URL_LENGTH) {
      url.searchParams.getAll('ids').forEach(el => hashParams.append('ids', el));
      url.searchParams.delete('ids');
      url.searchParams.getAll('messages').forEach(el => hashParams.append('messages', el));
      url.searchParams.delete('messages');
    }
    url.hash = hashParams.toString();
    return url;
  };

  // Convert query string parameters into internal app parameters; remove any params that are API-only,
  // e.g. exportFormat, targetID, etc.
  static parseQueryStringParams = (queryStr, hash = "") => {
    if (hash.startsWith("#")) {
      hash = hash.substring(1);
    }
    // Excel botches URL fragments, try to fix that up here
    if (hash.indexOf("ids%3D") !== -1) {
      hash = decodeURIComponent(hash);
    }
    const searchParams = new URLSearchParams(`${queryStr}&${hash}`);
    const params = { searchIDs: []};
    searchParams.forEach((val, name) => {
      const jsKey = API2JS[name] || name.replace(/([a-z])_(id|[a-z])/g, ($0, $1, $2) => `${$1}${$2.toUpperCase()}`);
      if (name === "ids") {
        const ids = updateLegacyIDs(typeof(val) === "string" ? [val] : val)
          .reduce((result, el) => {
            if (el.trim() !== "" && el.indexOf(":") !== -1) {
              const [id, label] = extractLabel(el);
              if (label) {
                saveCustomLabel(id, label)
              }
              result.push(id);
            }
            return result;
          }, []);
        // Ensure all values are unique and sorted
        params[jsKey] = Array.from(new Set(params[jsKey].concat(ids))).sort();
      }
      else if (name === "sim_types") {
        // Convert obsolete usage into current usage
        if (val.indexOf(":") !== -1) {
          const [type, simThreshold] = val.split(":", 2);
          params[jsKey] = type;
          params["simThreshold"] = parseFloat(simThreshold);
        } else {
          params[jsKey] = val;
        }
      }
      else if (INTPARAMS.has(name)) {
        params[jsKey] = parseInt(val, 10);
      }
      else if (BOOLPARAMS.has(name)) {
        params[jsKey] = val === "true" || val === "";
      }
      else if (FLOATPARAMS.has(name)) {
        params[jsKey] = parseFloat(val);
      }
      else if (IGNOREPARAMS.has(name)) {
        // These parameters don't belong in a search URL
      }
      else if (/^[a-z][_a-z]+$/.test(name)) {
          params[jsKey] = val;
      }
      else {
        toast.warning(`Ignoring invalid parameter ${name}`)
      }
    });
    return params;
  };
}

export default SearchContext;
