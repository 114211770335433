import {useEffect, useState} from "react";
import {DropdownButton} from "react-bootstrap";
import DropdownFilter from "./DropdownFilter";
import SimpleSelect from "./controls/SimpleSelect";
import {getConfig, getEnv} from "../lib/configMgr";
import NumericInput from "./controls/NumericInput";
import Checkbox from "./controls/Checkbox";
import _ from "lodash";
import {extractVersion} from "./ChatSession";

const DEFAULT_MODEL = "default";


export const ChatSettings = ({onChange = (s) => {}, categories = [], userStore, appStatusStore, disabled=false}) => {

  const {isAdmin} = userStore;
  const {aiModels = {}} = appStatusStore;
  const availableModels = Object.entries({[DEFAULT_MODEL]: "default", ...aiModels});

  const SNAPSHOT_SIZES = isAdmin ? [0, 5, 10, 20, 25, 50, 100, 250, 500] : [0, 5, 10, 20];
  const SNAPSHOT_OPTIONS = SNAPSHOT_SIZES.map(x => `${x}`);

  const allCategories = Object.values(categories || []).filter(x => x.id !== "cpdset" && x.id !== "geneset");
  const defaultOffCategories = new Set([]);

  const [useTemperature, setUseTemperature] = useState(true);
  const [temperature, setTemperature] = useState(0.1);
  const [topP, setTopP] = useState(0.9);
  const [topK, setTopK] = useState(null);
  const [useEdges, setUseEdges] = useState(false);
  const [stream, setStream] = useState(getEnv() === "dev");
  const [model, setModel] = useState(DEFAULT_MODEL);
  const [snapshotSize, setSnapshotSize] = useState(SNAPSHOT_OPTIONS[0]);
  const [selectedCategories, setSelectedCategories] = useState(null);

  useEffect(() => {
    onChange({
      ...(model !== DEFAULT_MODEL ? {model} : {}),
      snapshotSize,
      stream,
      top_k: topK,
      ...(selectedCategories && selectedCategories?.length !== allCategories?.length
          ? {categories: selectedCategories.map(el => el.id)}
          : {}),
      // Use T or P, not both
      ...(useTemperature ? {temperature} : {top_p: topP}),
    });
  }, [model, snapshotSize, stream, topK, selectedCategories, useTemperature, temperature, topP])

  useEffect(() => {
    if (selectedCategories == null && allCategories.length) {
      setSelectedCategories(allCategories.filter(cat => !defaultOffCategories.has(cat.id)));
    }
  }, [allCategories.length]);

  const handleCategoryFilterApply = (categories) => {
    setSelectedCategories(categories.length === allCategories.length ? null : categories);
  };
  const handleCategoryFilterClear = () => {
    setSelectedCategories(null);
  }

  return (
    <div className={"chat-settings"}>
      <div className={"vertical"}>
        {isAdmin && (
          <>
            <span className={"admin"}>
              Model&nbsp;
              <select
                onChange={(e) => setModel(e.target.value)}
                defaultValue={model}
                disabled={disabled}
                key={"model-changer"}
                title={"Choose the LLM model"}
              >
                {availableModels
                  .sort(([aid, aname], [bid, bname]) =>
                          aname.toLocaleLowerCase().localeCompare(bname.toLocaleLowerCase()))
                  .map(([modelID, modelName]) => (
                  <option key={modelID} value={modelID} title={modelID}>{`${modelName}${extractVersion(modelID)}`}</option>
                ))}
              </select>
            </span>
            <div key={"model-settings"} className="admin model-settings">
              <div className={"temp-p"} >
                <SimpleSelect
                  disabled={disabled}
                  title={useTemperature
                         ? "Randomness (affects conservative vs. creative)"
                         : "Option probability threshold (affects diversity of outputs)"}
                  options={["T", "P"]}
                  onChange={v => setUseTemperature(v === "T")}
                />
                <NumericInput
                  disabled={disabled}
                  min={0} max={1} step={0.1} precision={2} value={useTemperature ? temperature : topP}
                  onChange={v => useTemperature ? setTemperature(v) : setTopP(v)}/>
              </div>
              <Checkbox
                disabled={disabled}
                label={"K"}
                title={"Sample top K options (remove long tails of low-probability responses)"}
                checked={topK != null}
                onChange={v => setTopK(v ? 500 : null)}
              >
                <NumericInput
                  disabled={disabled || topK == null}
                  min={0} max={500} step={5} precision={0} value={topK}
                  onChange={v => setTopK(v)}/>
              </Checkbox>
            </div>
            <Checkbox
              disabled={disabled}
              className="admin"
              label="Stream Response"
              title="Stream LLM responses"
              checked={stream}
              onChange={s => setStream(s)}
            />
          </>
        )}
        <div key="buttons" className="horizontal buttons">
          <SimpleSelect
            label={"Use top"}
            onChange={(value) => setSnapshotSize(value)}
            disabled={disabled}
            value={snapshotSize}
            options={SNAPSHOT_OPTIONS}
            getOptionLabel={(x) => (`${x}` === "0" ? "auto" : `${x}`)}
          />
          <DropdownButton
            title={<span className="glyphicon glyphicon-filter"/>}
            className={`categories-selection${selectedCategories == null ? '' : ' active'}`}
            align="end"
            disabled={disabled}
          >
            <DropdownFilter
              items={allCategories}
              currentFilterValue={selectedCategories}
              getKey={x => x.id}
              getLabel={x => x.name}
              getTitle={x => x.description}
              onFilterApply={handleCategoryFilterApply}
              onFilterClear={handleCategoryFilterClear}
              disabled={disabled}
            />
          </DropdownButton>
        </div>
      </div>
    </div>
  )
};
